import { ReactNode } from "react";

import { SerializedStyles, css } from "@emotion/react";

import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { isViewMobile } from "utils/mediaQueryStrings";

import { uiGray } from "constants/colors";

import { Tooltip } from "../library/Tooltip";
import { Typography } from "../library/Typography";

type Props = {
  children: ReactNode;
  label: string;
  customCss?: { wrapper?: SerializedStyles };
};
export const SidePanelTooltip = ({ children, label, customCss }: Props) => {
  const { leftPanelStatus } = usePageLayoutContext();

  return (
    <Tooltip
      content={
        <Typography size="XS" weight="regular" color={uiGray[60]}>
          {label}
        </Typography>
      }
      showTooltip={
        leftPanelStatus === "collapsed" && !isViewMobile() ? "onHover" : "never"
      }
      position="right"
      customCss={{
        wrapper: css([customCss?.wrapper]),
      }}
    >
      {children}
    </Tooltip>
  );
};
