import { css } from "@emotion/react";

import { RecruitPlanName } from "apis/recruitingTeam";

import { Badge, Props as BadgeProps } from "../library/Badge";

const style = {
  badge: css({ padding: "4px 8px", borderRadius: 4 }),
};

export type Props = {
  plan: "pro" | "cancelled" | "recruit" | "free" | "trial" | RecruitPlanName;
  size?: BadgeProps["size"];
};

export const PlanBadge = ({ plan, size = "small" }: Props) => {
  const colorMap: Record<
    "pro" | "cancelled" | "recruit" | "free" | "trial" | RecruitPlanName,
    BadgeProps["color"]
  > = {
    pro: "purple",
    cancelled: "red",
    recruit: "grey",
    free: "grey",
    trial: "grey",
    Starter: "grey",
    Growth: "purple",
    Business: "purple",
    Enterprise: "purple",
  };

  return (
    <Badge
      label={plan.toUpperCase()}
      color={colorMap[plan]}
      customCss={style.badge}
      size={size}
    />
  );
};
