import { ChevronLeftDoubleFilledIcon } from "Icons/ChevronLeftDoubleFilled";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { useAccount } from "contexts/AccountContext";
import { useRecruitPlanContext } from "contexts/PlanGatingContext";

import { getRecruitUrl } from "utils/urls";

import { Authenticated } from "../library/Authenticated";
import { Button } from "../library/Button";
import RibbonLogo from "../library/RibbonLogo";
import { PlanBadge } from "../ribbonPro/PlanBadge";

type Props = {
  showLogoOnly?: boolean;
  onClick?: () => void;
};

const style = {
  sidePanel: css({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  }),
  badge: css({
    whiteSpace: "nowrap",
  }),
  header: css({
    display: "flex",
    gap: 10,
    alignItems: "center",
  }),
};

export const BrandHeader = ({ showLogoOnly, onClick }: Props) => {
  const router = useRouter();
  const { accountProfile } = useAccount(false);
  const { plan } = useRecruitPlanContext();

  const isRecruit = router.asPath.includes(getRecruitUrl());

  return (
    <div css={style.sidePanel}>
      <div css={style.header}>
        <RibbonLogo isLink showLogoOnly={showLogoOnly} />
        {!showLogoOnly && !!accountProfile && (
          <Authenticated>
            <div css={style.badge}>
              {isRecruit && plan.hasPaymentMethod && (
                <PlanBadge plan={plan.planName} />
              )}

              {isRecruit && !plan.hasPaymentMethod && (
                <PlanBadge plan="trial" />
              )}

              {!isRecruit && <PlanBadge plan={"pro"} />}
            </div>
          </Authenticated>
        )}
      </div>
      {!showLogoOnly && (
        <Button
          color="gray"
          isSquare
          background="ghost"
          size="small"
          onClick={onClick}
          IconLeft={ChevronLeftDoubleFilledIcon}
          ariaLabel="Collapse"
        />
      )}
    </div>
  );
};
