import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { getSignUpUrl } from "utils/urls";

import { purple, white } from "constants/colors";

import { ButtonLink } from "../library/ButtonLink";
import { Typography } from "../library/Typography";

const style = {
  wrapper: css({
    display: "flex",
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    borderRadius: 16,
    background: purple[800],
  }),
};

export const SignUpWidget = () => {
  const router = useRouter();
  return (
    <div css={style.wrapper}>
      <Typography color={white} weight="bold">
        Join Ribbon
      </Typography>

      <ButtonLink
        color="gray"
        background="filled"
        size="small"
        href={getSignUpUrl({ currentPath: router.asPath })}
      >
        Try for free
      </ButtonLink>
    </div>
  );
};
