import { HelpCircleIcon } from "Icons/HelpCircle";
import { Stars01Icon } from "Icons/Stars01";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { css } from "@emotion/react";

import { useRouter } from "next/router";
import { selectAccountProfile } from "store/accountProfileSlice";

import { useAlert } from "contexts/alertContext";

import { getViewIncentiveVariables } from "apis/earningTransaction";

import { getExternalProfileUrl, getProfileUrl } from "utils/urls";

import { uiGray, white } from "constants/colors";

import { Button } from "../library/Button";
import { CopyToClipBoardIconButton } from "../library/CopyToClipBoardIconButton";
import { Tooltip } from "../library/Tooltip";
import { Typography } from "../library/Typography";

const style = {
  earningsArea: css({
    borderRadius: 16,
    background: white,
    width: 313,
    height: 308,
    gap: 16,
    display: "flex",
    margin: 16,
  }),
  innerContainer: css({
    padding: "16px 24px 16px 24px",
    height: 268,
    width: 265,
    gap: 16,
    display: "grid",
  }),
  content: css({
    gap: 8,
    width: 265,
    height: 156,
  }),
  header: css({
    padding: "6px 0px 6px 0px",
    display: "flex",
    alignItems: "center",
    gap: 10,
  }),
  description: css({
    gap: 16,
    display: "grid",
  }),
};

export const EarningsRightPanel = () => {
  const [amountInDollars, setAmountInDollars] = useState<number>(0);
  const [viewsUnit, setViewsUnit] = useState<number>(0);
  const { createAlert } = useAlert();
  const accountProfile = useSelector(selectAccountProfile);
  const router = useRouter();
  useEffect(() => {
    const get = async () => {
      const data = await getViewIncentiveVariables();
      setAmountInDollars(data.amountInCents / 100); // convert to dollars
      setViewsUnit(data.typeUnit);
    };
    get();
  }, [createAlert]);
  if (!accountProfile) return null;
  return (
    <div css={style.earningsArea}>
      <div css={style.innerContainer}>
        <div css={style.content}>
          <div css={style.header}>
            <Typography size="M" weight="bold">
              Earn with profile views
            </Typography>
            <Tooltip
              content={`Start by copying your profile link to share it with your external network.`}
              position="left"
            >
              <HelpCircleIcon stroke={uiGray[70]} width={20} height={20} />
            </Tooltip>
          </div>
          <div css={style.description}>
            <Typography size="XS" weight="regular" color={uiGray[70]}>
              The more views you get on your profile, the more you get to earn
              and cash out.
            </Typography>
            <Typography size="XS" weight="regular" color={uiGray[70]}>
              {`For every view, you earn $${(
                amountInDollars / viewsUnit
              ).toFixed(
                2
              )} USD. So for reaching ${viewsUnit} views, you can earn $${amountInDollars.toFixed(
                2
              )} USD!`}
            </Typography>
          </div>
        </div>
        <CopyToClipBoardIconButton
          iconPosition="left"
          textToCopy={getExternalProfileUrl(accountProfile.handle)}
          displayLabel
          ariaLabel="Copy profile link"
          color="black"
          background="filled"
          successMessage="Profile link copied"
          isFullWidth
        />
        <Button
          onClick={() => {
            router.push(getProfileUrl(accountProfile.handle));
          }}
          background="outlined"
          color="black"
          size="medium"
          isFullWidth
          IconLeft={Stars01Icon}
        >
          Update profile
        </Button>
      </div>
    </div>
  );
};
