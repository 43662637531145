import { AnnotationCheckIcon } from "Icons/AnnotationCheck";
import { ArrowNarrowRightIcon } from "Icons/ArrowNarrowRight";
import { Bell01Filled } from "Icons/Bell01Filled";
import { Briefcase02 } from "Icons/Briefcase02";
import { Download01Icon } from "Icons/Download01";
import { FileAttachment05Icon } from "Icons/FileAttachment05";
import { FileHeart02Icon } from "Icons/FileHeart02";
import { Grid01Icon } from "Icons/Grid01";
import { LogIn01Icon } from "Icons/LogIn01";
import { MessageChatCircleIcon } from "Icons/MessageChatCircle";
import { MessageSmileCircleIcon } from "Icons/MessageSmileCircle";
import { Rocket01Icon } from "Icons/Rocket01";
import { SettingsIcon } from "Icons/Settings";
import { SwitchHorizontal02Icon } from "Icons/SwitchHorizontal02";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { css } from "@emotion/react";

import { selectNewNotifsCount } from "store/notificationsSlice";

import { Button } from "components/shared/library//Button";
import { Authenticated } from "components/shared/library/Authenticated";
import { ButtonLink } from "components/shared/library/ButtonLink";
import { Divider } from "components/shared/library/Divider";

import { useAccount } from "contexts/AccountContext";
import { usePageLayoutContext } from "contexts/PageLayoutContext";

import { useNotificationsRealtime } from "apis/notifications";
import { useIsOnboardingComplete } from "apis/onboardings";

import { isRibbonExtensionInstalled } from "utils/chromeExtension";
import { down } from "utils/mediaQueryStrings";
import {
  getChatOverviewUrl,
  getDashboardUrl,
  getDocumentsUrl,
  getExternalChromeExtensionUrl,
  getInterviewPlusUrl,
  getJobsCopilotUrl,
  getJobsUrl,
  getMyInterviewsUrl,
  getNotificationsUrl,
  getPersonalSettingsUrl,
  getPublicResumeUrl,
  getRecruitUrl,
  getResumeUrl,
  getSignUpUrl,
} from "utils/urls";

import { pink, purple } from "constants/colors";

import { NavigationBadge } from "./NavigationBadge";
import { RecruitAIModal } from "./RecruitAIModal";
import { SidePanel } from "./SidePanel";
import { SidePanelItem } from "./SidePanelItem";
import { SidePanelOrgDrawer } from "./SidePanelOrgDrawer";
import { SidePanelTooltip } from "./SidePanelTooltip";
import { UserAccountDisplay } from "./UserAccountDisplay";

const getStyles = (collapsed: boolean) => {
  return {
    divider: css({
      margin: "12px 0",
    }),
    notificationBadge: css({
      position: "relative",
      top: -45,
      left: 18,
      height: 0,
    }),
    content: css({
      display: "flex",
      flexDirection: "column",
      width: collapsed ? 36 : 240,
      gap: 16,
    }),
    userAccount: css({
      padding: !collapsed ? 0 : "0px 0px 16px 0px",
      [down("mobile")]: {
        marginTop: 24,
        padding: 0,
      },
    }),
    sidePanelItems: css({
      display: "flex",
      flexDirection: "column",
      gap: 8,
      marginTop: collapsed ? 12 : 0,
    }),
    bottomDiv: css({
      marginTop: "auto",
      display: "flex",
      flexDirection: "column",
      gap: 8,
    }),
    loginBtn: css({
      width: "100%",
    }),
    extensionRibbonPro: css({
      display: "flex",
      flexDirection: "column",
      gap: 16,
      [down("mobile")]: {
        gap: 4,
      },
      alignItems: "center",
    }),
    extensionRibbonProCollapsed: css({
      width: 36,
    }),
    upgradeBtnWrapper: css({
      background: purple[50],
      padding: "12px 16px 4px 16px",
      whiteSpace: "nowrap",
      borderRadius: "8px",
      width: "-webkit-fill-available",
    }),
    upgradeBtnWrapperCollapsed: css({
      padding: "8px",
    }),
    upgradeBtn: css({
      height: 20,
    }),
    chromeExtensionBtn: css({
      [down("mobile")]: {
        display: "none",
      },
    }),
    upgradeBtnClip: css({
      background: "transparent",
    }),
    getMoreDiv: css({
      width: "100%",
    }),
  };
};

export const MainSidePanel = () => {
  const { leftPanelStatus } = usePageLayoutContext();
  const [isOrgDrawerOpen, setIsOrgDrawerOpen] = useState<boolean>(false);
  const [isRecruitModalOpen, setIsRecruitModalOpen] = useState<boolean>(false);
  const notifCount = useSelector(selectNewNotifsCount);
  const { accountProfile } = useAccount(false);
  const { isLoaded: isOnboardingLoaded, isOnboardedToRecruit } =
    useIsOnboardingComplete(accountProfile?.id);

  const [showExtensionButton, setShowExtensionButton] = useState(false);
  useNotificationsRealtime(accountProfile?.id);
  const style = getStyles(leftPanelStatus === "collapsed");
  useEffect(() => {
    isRibbonExtensionInstalled().then((isInstalled) => {
      if (!isInstalled) {
        setShowExtensionButton(true);
      }
    });
  }, []);

  return (
    <SidePanel setIsOrgDrawerOpen={setIsOrgDrawerOpen}>
      <RecruitAIModal
        isOpen={isRecruitModalOpen}
        onClose={() => setIsRecruitModalOpen(false)}
      />
      <div css={style.content}>
        <Authenticated>
          <div css={style.userAccount}>
            <UserAccountDisplay
              showAvatarOnly={leftPanelStatus === "collapsed"}
            />
          </div>
        </Authenticated>
        <div css={style.sidePanelItems}>
          <Authenticated>
            <SidePanelItem
              label="Dashboard"
              link={getDashboardUrl()}
              Icon={Grid01Icon}
              disableChildPathAsActive
            />
          </Authenticated>
          <SidePanelItem label="Jobs" link={getJobsUrl()} Icon={Briefcase02} />
          <SidePanelItem
            label="Chat AI"
            link={getChatOverviewUrl()}
            Icon={MessageSmileCircleIcon}
          />

          <Authenticated>
            <SidePanelItem
              label="Copilot"
              link={getJobsCopilotUrl()}
              Icon={Rocket01Icon}
            />
          </Authenticated>

          <SidePanelItem
            label="Resume+"
            link={accountProfile?.id ? getResumeUrl() : getPublicResumeUrl()}
            Icon={FileHeart02Icon}
          />

          <Authenticated>
            <SidePanelItem
              label="Practice with Interview+"
              link={getInterviewPlusUrl()}
              Icon={MessageChatCircleIcon}
            />
          </Authenticated>

          <Authenticated>
            <SidePanelItem
              label="My interviews"
              link={getMyInterviewsUrl()}
              Icon={AnnotationCheckIcon}
              isNew
            />
          </Authenticated>

          <Authenticated>
            <SidePanelOrgDrawer
              isOpen={isOrgDrawerOpen}
              setIsOpen={(isOpen: boolean) => {
                setIsOrgDrawerOpen(isOpen);
              }}
            />
          </Authenticated>
          <Authenticated>
            <Divider customCss={style.divider} />

            <Authenticated>
              <SidePanelItem
                label="My documents"
                link={getDocumentsUrl()}
                Icon={FileAttachment05Icon}
                disableChildPathAsActive
              />
            </Authenticated>

            <SidePanelItem
              label="Notifications"
              link={getNotificationsUrl()}
              Icon={Bell01Filled}
              RightComponent={
                notifCount ? (
                  <NavigationBadge label={`${notifCount}`} />
                ) : undefined
              }
              SecondaryComponent={
                !!notifCount &&
                notifCount > 0 &&
                leftPanelStatus === "collapsed" ? (
                  <div css={style.notificationBadge}>
                    <svg
                      width="8"
                      height="8"
                      viewBox="0 0 10 10"
                      fill={pink[600]}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="5"
                        cy="5"
                        r="5"
                        stroke="white"
                        strokeWidth={"0.5"}
                      />
                    </svg>
                  </div>
                ) : undefined
              }
            />
          </Authenticated>
          <Authenticated>
            <SidePanelItem
              label="Settings"
              link={getPersonalSettingsUrl()}
              Icon={SettingsIcon}
            />
          </Authenticated>
        </div>
      </div>
      <div css={style.bottomDiv}>
        {!(leftPanelStatus === "collapsed") &&
          isOnboardedToRecruit &&
          isOnboardingLoaded && (
            <ButtonLink
              href={getRecruitUrl()}
              background="ghost"
              color="gray"
              size="medium"
              sameTab
              IconRight={SwitchHorizontal02Icon}
            >
              Switch to Recruit AI
            </ButtonLink>
          )}
        {!(leftPanelStatus === "collapsed") &&
          !isOnboardedToRecruit &&
          isOnboardingLoaded && (
            <Button
              background="ghost"
              size="medium"
              onClick={() => setIsRecruitModalOpen(true)}
              IconRight={ArrowNarrowRightIcon}
            >
              Recruiting? Try Recruit AI
            </Button>
          )}
        {!accountProfile?.id && (
          <div css={style.loginBtn}>
            <ButtonLink
              href={getSignUpUrl({})}
              size="small"
              ariaLabel="sign-up"
              IconLeft={LogIn01Icon}
              isFullWidth
            >
              {!(leftPanelStatus === "collapsed") && "Sign up for free"}
            </ButtonLink>
          </div>
        )}
        <div
          css={[
            style.extensionRibbonPro,
            leftPanelStatus === "collapsed"
              ? style.extensionRibbonProCollapsed
              : undefined,
          ]}
        >
          {showExtensionButton && accountProfile?.id && (
            <SidePanelTooltip label="Download chrome extension">
              <div css={style.chromeExtensionBtn}>
                <ButtonLink
                  href={getExternalChromeExtensionUrl()}
                  size="small"
                  ariaLabel="download-extension"
                  IconLeft={Download01Icon}
                  isFullWidth
                >
                  {!(leftPanelStatus === "collapsed") &&
                    "Download Chrome Extension"}
                </ButtonLink>
              </div>
            </SidePanelTooltip>
          )}
        </div>
      </div>
    </SidePanel>
  );
};
