import { AlertTriangleIcon } from "Icons/AlertTriangle";

import { css } from "@emotion/react";

import { UpgradePlanButton } from "components/recruit/shared/UpgradePlanButton";

import { useRecruitPlanContext } from "contexts/PlanGatingContext";

import { useTotalInterviewCallsRealtime } from "apis/recruit";
import { useRecruiterTeam } from "apis/recruitingTeam";

import { getFormatDistanceDate } from "utils/time";

import { purple, red, uiGray, white, yellow } from "constants/colors";

import { Typography } from "../library/Typography";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  counter: css({
    display: "flex",
    padding: "10px 16px",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 100,
    border: `1px solid ${uiGray[10]}`,
    background: white,
  }),
  warning: css({
    borderColor: yellow[500],
    background: "#FFFDF2",
  }),
  error: css({
    borderColor: red[400],
    background: red[100],
  }),
  count: css({
    display: "flex",
    alignItems: "center",
    gap: 4,
  }),
  upgrade: css({
    display: "flex",
    padding: 16,
    alignItems: "center",
    justifyContent: "space-between",
    gap: 8,
    borderRadius: 16,
    background: purple[800],
  }),
};

export const InterviewCallsCounter = () => {
  const { team } = useRecruiterTeam();
  const { count: totalInterviewCount, isLoaded: isInterviewCountLoaded } =
    useTotalInterviewCallsRealtime();

  const { plan } = useRecruitPlanContext();

  const isWarning =
    plan.interviewUsageForCurrentInterval >
    0.5 * plan?.includedInterviewsPerRecurringInterval;

  const isError =
    plan.interviewUsageForCurrentInterval >
    0.9 * plan?.includedInterviewsPerRecurringInterval;

  return (
    <div css={style.wrapper} data-testid="interview-calls-counter">
      {/* trial - no payment method */}
      {!plan.hasPaymentMethod && isInterviewCountLoaded && (
        <div
          css={[
            style.counter,
            isWarning && style.warning,
            isError && style.error,
          ]}
        >
          <div css={style.count}>
            <Typography color={uiGray[70]} size="XS" weight="medium">
              {`${totalInterviewCount} / ${plan.includedInterviewsPerRecurringInterval}`}
            </Typography>
            <Typography color={uiGray[70]} size="XS" weight="medium">
              interviews used
            </Typography>
          </div>

          {(isWarning || isError) && (
            <AlertTriangleIcon stroke={isError ? red[900] : yellow[600]} />
          )}
        </div>
      )}

      {/* non-trial w/ payment method */}
      {plan.hasPaymentMethod && (
        <div css={[style.counter]}>
          <div css={style.count}>
            <Typography color={uiGray[70]} size="XS" weight="medium">
              {plan.interviewUsageForCurrentInterval}{" "}
            </Typography>
            <Typography color={uiGray[70]} size="XS" weight="medium">
              interviews this month
            </Typography>
          </div>
        </div>
      )}

      {/* upgrade widget */}
      {!plan.hasPaymentMethod && team?.isOwner && plan.trialEnd && (
        <div css={style.upgrade}>
          <Typography color={white} weight="bold">
            Trial ends {getFormatDistanceDate(new Date(plan.trialEnd))}
          </Typography>
          <UpgradePlanButton
            color="gray"
            background="filled"
            size="small"
            currentPlan={plan}
          >
            Upgrade
          </UpgradePlanButton>
        </div>
      )}
    </div>
  );
};
