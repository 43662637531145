import { CheckColorIcon } from "Icons/CheckColor";

import { SerializedStyles, css } from "@emotion/react";

import { Org } from "apis/orgs";

import { Tooltip } from "../library/Tooltip";
import { Typography } from "./Typography";

const style = {
  orgName: css({
    display: "flex",
    gap: 8,
    alignItems: "center",
  }),
};

type Props = {
  org: Pick<Org, "name" | "isVerified">;
  weight?: "regular" | "bold";
  size?: "XS" | "S" | "XL";
  customCss?: {
    orgName: SerializedStyles;
  };
};

export const OrgVerified = ({
  org,
  size = "S",
  weight = "regular",
  customCss,
}: Props) => {
  return (
    <Typography
      customCss={customCss?.orgName ? customCss?.orgName : style.orgName}
      size={size}
      weight={weight}
    >
      {org.name}
      {org.isVerified && (
        <Tooltip
          content={"This organization is verified by Ribbon"}
          position="right"
        >
          <CheckColorIcon width={16} height={16} />
        </Tooltip>
      )}
    </Typography>
  );
};
